import { Button, Card, Flex, Input, Typography } from "antd";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
const { Title } = Typography;

export const Login = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const onChangeLogin = (e) => {
    setLogin(e.target.value?.toLowerCase());
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    if (login && password) {
      fetch("/api/login", {
        method: "POST",
        headers: { "Content-Type": "application/json", Accept: "*/*" },
        body: JSON.stringify({ login, password }), // Только потом сделать хэширование пароля перед отправкой
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status === 200) {
            /*
            accessToken: accessToken,
            refreshToken: refreshToken,
            userId: docs[0].userId,
            homeId: docs[0].homeId,
            */
            localStorage.setItem("hutor-user", JSON.stringify(data.user));
            localStorage.setItem(
              "hutor-user-accessToken",
              data.user.accessToken
            );
            localStorage.setItem(
              "hutor-user-refreshToken",
              data.user.refreshToken
            );
            localStorage.setItem("hutor-user-id", data.user.userId);
            localStorage.setItem("hutor-user-homeId", data.user.homeId);
            navigate("/");
          } else {
            setError("Произошла ошибка " + data.error);
          }
        });
    } else {
      setError("Заполните логин и пароль");
    }
  };

  return (
    <>
      <Card className="loginCard">
        <Flex gap={16} vertical>
          <Flex justify="center" vertical>
            <img
              style={{
                height: "60px",
                width: "60px",
                border: "1px solid #d4d4d4",
                borderRadius: "14px",
                margin: "0 auto",
              }}
              src="/hutor_icon.png"
            />
            <img
              style={{
                width: "100px",
                margin: "8px auto 0px",
              }}
              src="/text-logo.png"
            />
          </Flex>
          <Title level={2} style={{ marginTop: "0", textAlign: "center" }}>
            Войти в систему
          </Title>

          <Input
            className="loginInput"
            placeholder="Логин"
            size="large"
            onChange={onChangeLogin}
            prefix={<UserOutlined />}
          />
          <Input
            type="password"
            placeholder="Пароль"
            size="large"
            onChange={onChangePassword}
            prefix={<KeyOutlined />}
            onPressEnter={handleLogin}
          />
          {error && (
            <p style={{ fontSize: "12px", color: "red", margin: 0 }}>{error}</p>
          )}
          {/* <p style={{ margin: 0 }}>
            Нет учетной записи? Тогда <Link>зарегистрируйтесь</Link>
          </p> */}

          <Flex justify="center">
            <Button
              type="primary"
              style={{ width: "140px" }}
              onClick={handleLogin}
            >
              Вход
            </Button>
          </Flex>
          <Flex justify="center" style={{ marginTop: "16px" }}>
            <Link to="/sign-in" style={{ color: "#5cba1e" }}>
              Зарегистрироваться
            </Link>
          </Flex>
        </Flex>
      </Card>
    </>
  );
};
