import { Typography, Button, Flex } from "antd";
// import { AddDeviceModal } from "./AddDeviceModal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { toggleAddModal } from "../../../store/slices/deviceListSlice";

const { Title } = Typography;

export const HeaderPanel = (props) => {
  const navigate = useNavigate();

  return (
    <Flex align="center">
      <Flex flex={1}>
        <Title level={3}>Сценарии и автоматизация</Title>
      </Flex>
      <Flex align="flex-end">
        <Button
          onClick={() => {
            navigate("/scenarios/create");
          }}
        >
          +
        </Button>
      </Flex>
    </Flex>
  );
};
