import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchRequest } from "../../../../../utils/fetchRequest";

export const fetchPetrolHistrory = createAsyncThunk(
  "petrolHistory/get",
  async (id, thunkApi) => {
    const data = await fetchRequest(`/api/data-server/${id}`);
    return data;
  }
);
export const deletePetrolHistory = createAsyncThunk(
  "petrolHistory/delete",
  async ({ id, docId }, thunkApi) => {
    const data = await fetchRequest(`/api/data-server/${id}/${docId}`, {
      method: "delete",
    });
    thunkApi.dispatch(fetchPetrolHistrory(id));
    return data;
  }
);

export const petrolDataSlice = createSlice({
  name: "petrol_slice",
  initialState: {
    data: [],
    loading: true,
    isAddModalOpen: false,
  },
  reducers: {
    setIsAddModalOpen: (state, action) => {
      state.isAddModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPetrolHistrory.fulfilled, (state, action) => {
      state.data = action.payload.map((elem) => ({
        ...elem.doc,
        _id: elem._id,
        index: elem.index,
      }));
    });
  },
});

export const { setIsAddModalOpen } = petrolDataSlice.actions;
export default petrolDataSlice.reducer;
