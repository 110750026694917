import { HeaderPanel } from "./componets/HeaderPanel";
import { useEffect, useRef } from "react";
import { ThermostatCard } from "./componets/Cards/ThermostatCard";
import { SwitchCard } from "./componets/Cards/SwitchCard";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "../../../../utils/store/store";
import { fetchDeviceList } from "../../store/slices/deviceListSlice";
import { Flex, Typography } from "antd";
import { GatesCard } from "./componets/Cards/GatesCard";
import { CustomCard } from "./componets/Cards/CustomCard";
import { PetrolCard } from "./componets/Cards/PetrolCard";
// TODO: Попробовать сделать StaggeredList и еще DnD добавить
// https://github.com/wakaztahir/react-staggered-grid?tab=readme-ov-file

const { Text, Title } = Typography;
export const DeviceListWrapper = (props) => {
  return (
    <Provider store={store}>
      <DeviceList />
    </Provider>
  );
};

export const DeviceList = (props) => {
  const dispatch = useDispatch();
  const deviceList = useSelector((state) => state.deviceList);

  useEffect(() => {
    dispatch(fetchDeviceList());
  }, []);

  const renderCard = (device) => {
    switch (device._id.substring(0, 3)) {
      case "THS":
        return <ThermostatCard id={device._id} data={device} />;
      case "SWH":
        return <SwitchCard id={device._id} data={device} />;
      case "GTS":
        return <GatesCard id={device._id} data={device} />;
      case "PET":
        return <PetrolCard id={device._id} data={device} />;

      default:
        return <CustomCard id={device._id} data={device} />;
        break;
    }
  };

  const renderEmptyList = () => {
    return (
      <Flex
        vertical
        justify="center"
        align="center"
        style={{ width: "90%", margin: "5rem auto" }}
      >
        <div>
          <img
            src="/device_2x.png"
            style={{
              height: "50px",
            }}
          />
        </div>
        <Title level={4} type="secondary">
          Нет устройств
        </Title>
        <Text type="secondary">Добавьте устройства, нажав на кнопку "+"</Text>
      </Flex>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <HeaderPanel />

      {/* --- Список-- */}
      <Flex wrap="wrap" gap={16}>
        {deviceList?.list.length
          ? deviceList?.list.map((device) => {
              return renderCard(device);
            })
          : renderEmptyList()}
      </Flex>
    </div>
  );
};
