import { Typography, Card } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Title } = Typography;

const ScenarioCard = (props) => {
  const scenario = props.item;
  return (
    <Card className="ScenarioCard">
      <Link to={`/scenarios/${props.item._id}`}>
        <div>
          <Title level={5} style={{ marginTop: "0px" }}>
            {props.title}
          </Title>
          <div style={{ display: "flex", gap: "8px" }}>
            {scenario.automationConditions?.map((condition) => {
              return (
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    backgroundColor: "#333",
                    borderRadius: "8px",
                  }}
                ></div>
              );
            })}

            <ArrowRightOutlined />
            {scenario.actions?.map((action) => {
              return (
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    backgroundColor: "#333",
                    borderRadius: "8px",
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      </Link>
    </Card>
  );
};

export { ScenarioCard };
