import { Typography, Button, Flex } from "antd";
import { AddDeviceModal } from "./AddDeviceModal";
import { useDispatch } from "react-redux";
import { toggleAddModal } from "../../../store/slices/deviceListSlice";

const { Title } = Typography;

export const HeaderPanel = (props) => {
  const dispatch = useDispatch();

  return (
    <Flex align="center">
      <Flex flex={1}>
        <Title level={3}>Список устройств</Title>
      </Flex>
      <Flex align="flex-end">
        <Button
          onClick={() => {
            dispatch(toggleAddModal());
          }}
        >
          +
        </Button>
      </Flex>
      <AddDeviceModal />
    </Flex>
  );
};
