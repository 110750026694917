import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchRequest } from "../../../../utils/fetchRequest";

export const fetchScenariosList = createAsyncThunk(
  "scenarios_list/fetch",
  async (thunkApi) => {
    const data = await fetchRequest(
      `/api/scenarios?userId=${localStorage.getItem("hutor-user-id")}`
    );
    return data;
  }
);

// Создание
export const createScenario = createAsyncThunk(
  "scenarios_list/create",
  async (scenario, thunkApi) => {
    const data = await fetchRequest(
      `/api/scenarios?userId=${localStorage.getItem("hutor-user-id")}`,
      { method: "post" },
      JSON.stringify(scenario)
    );
    return data;
  }
);

// Редактирование
export const updateScenario = createAsyncThunk(
  "scenarios_list/update",
  async (scenario, thunkApi) => {
    const data = await fetchRequest(
      `/api/scenarios/${scenario.id}`,
      { method: "put" },
      JSON.stringify({ userId: scenario.userId, scenario: scenario.scenario })
    );
    return data;
  }
);

// Удаление
export const deleteScenario = createAsyncThunk(
  "scenarios_list/delete",
  async (id, thunkApi) => {
    const data = await fetchRequest(`/api/scenarios/${id}`, {
      method: "delete",
    });
    return data;
  }
);

export const scenariosListSlice = createSlice({
  name: "scenarios_list",
  initialState: {
    list: [],
    loading: true,
    isAddModalOpen: false,
  },
  reducers: {
    toggleAddModal: (state) => {
      state.isAddModalOpen = !state.isAddModalOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScenariosList.fulfilled, (state, action) => {
      console.log("red", action.payload);
      state.list = [...action.payload?.doc];
    });
  },
});

export default scenariosListSlice.reducer;
