import { useRef, useState, useEffect } from "react";
import mqtt from "mqtt";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Flex } from "antd";
import { setMqttData, setMqttClient } from "../store/mqttSlice";
import { useDispatch } from "react-redux";
import { MqttConnectionModal } from "./MqttConnectionModal";
import { fetchRequest } from "../fetchRequest";

export const MqttManager = () => {
  const [client, setClient] = useState(null);
  const clientRef = useRef(null);
  const [connectionStatus, setConnectStatus] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    loadConnectionInfo();
  }, []);

  const loadConnectionInfo = async () => {
    const data = await fetchRequest("/api/mqttBrokerConnectionInfo");
    mqttConnect(data?.data?.data);
  };

  const mqttConnect = (conn) => {
    setConnectStatus("Connecting");
    const c = mqtt.connect(`${conn.protocol}://${conn.host}:${conn.port}`, {
      username: conn.user,
      password: conn.password,
    }); //"ws://192.168.1.4:8888"
    setClient(c);
    dispatch(setMqttClient(c));
  };

  useEffect(() => {
    // mqttConnect({
    //   clientId: "HUTOR-web-app" + new Date(),
    // });
    setTimeout(() => {
      console.log("timeOut");

      setMqttData("to-");
      console.log("timeOut");
    }, 1000);
  }, []);

  useEffect(() => {
    if (client) {
      console.log(client);
      client.on("connect", () => {
        setConnectStatus("Connected");
        mqttSub({ topic: "#", qos: 1 });

        console.log("mqtt connect");
      });
      client.on("error", (err) => {
        console.error("Connection error: ", err);
        clientRef.current?.end();
      });
      client.on("reconnect", () => {
        console.log("mqtt reconnect");
        setConnectStatus("Reconnecting");
      });
      client.on("message", (topic, message) => {
        const payload = { topic, message: message.toString() };
        setMqttData(message.toString());
        dispatch(setMqttData(payload));
      });
    }
  }, [client]);

  const mqttSub = (subscription) => {
    if (client) {
      const { topic, qos } = subscription;
      client.subscribe(topic, { qos }, (error) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        console.log("mqtt subscribe at", subscription);
      });
    }
  };

  const mqttPublish = (context) => {
    if (client) {
      const { topic, qos, payload } = context;
      client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          console.log("Publish error: ", error);
        }
      });
    }
  };
  const shouldDisplayLabel = window.innerWidth > 640;

  return (
    <div>
      {connectionStatus === "Connected" ? (
        <MqttConnectionModal
          buttonText={
            <Flex gap={8}>
              <CheckCircleFilled style={{ color: "#5cba1e" }} />
              {shouldDisplayLabel ? "Подключено к MQTT брокеру" : ""}
            </Flex>
          }
          onChangeConnection={loadConnectionInfo}
        />
      ) : (
        <MqttConnectionModal
          buttonText={
            <Flex gap={8}>
              <CloseCircleFilled style={{ color: "#ff3b30" }} />
              {shouldDisplayLabel
                ? "Не удалось подключиться к MQTT брокеру"
                : ""}
            </Flex>
          }
          onChangeConnection={loadConnectionInfo}
        />
      )}
    </div>
  );
};
