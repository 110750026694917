import {
  HomeOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";

const SideNav = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const items = [
    {
      key: "home",
      icon: <HomeOutlined />,
      label: "Мой дом",
    },
    {
      key: "devices",
      icon: <AppstoreOutlined />,
      label: "Устройства",
    },
    {
      key: "scenarios",
      icon: <FileTextOutlined />,
      label: "Сценарии",
    },
    {
      key: "statistics",
      icon: <LineChartOutlined />,
      label: "Статистика",
    },
  ];

  return (
    <div className="sideNavMenuContainer">
      <Menu
        className="sideNavMenu"
        style={{
          maxWidth: "250px",
          borderRadius: "12px",
          margin: "16px",
        }}
        defaultSelectedKeys={[location.pathname?.replaceAll("/", "")]}
        mode="inline"
        inlineCollapsed={props.isOpen}
        items={items}
        onClick={(info) => {
          console.log("menu info", info);
          navigate("/" + info.key);
        }}
      />
    </div>
  );
};

export { SideNav };
