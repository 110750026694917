import { Typography, Slider, Flex } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PetrolHistoryTable } from "./PetrolHistoryTable";
import { AddModal } from "./AddModal";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
} from "@nextui-org/react";
import useLongPress from "../../../../../../utils/useLongPress";

const { Title } = Typography;
export const PetrolSettings = (props) => {
  const dispatch = useDispatch();
  const [showEditorDescription, setShowEditorDescription] = useState(false);

  const devicePage = useSelector((state) => state.devicePage);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openAddModal = () => {
    setIsModalOpen(true);
  };

  const onRowLongPress = useLongPress(() => {
    console.log("onRowLongPress");
  }, 2000);
  console.log("PTS", props);
  return (
    <div style={{ overflow: "scroll" }}>
      <Flex align="center">
        <Flex style={{ flexGrow: 5 }}>
          <Title level={5}>История</Title>
        </Flex>
        <Button onClick={openAddModal}>+</Button>
      </Flex>
      <AddModal
        id={props.id}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <PetrolHistoryTable id={props.id} />
    </div>
  );
};
