import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchRequest } from "../../../../utils/fetchRequest";

export const fetchDeviceList = createAsyncThunk("devices", async (thunkApi) => {
  const data = await fetchRequest(
    `/api/devices?userId=${localStorage.getItem("hutor-user-id")}`
  );
  return data;
});

export const deviceListSlice = createSlice({
  name: "device_list",
  initialState: {
    list: [],
    loading: true,
    isAddModalOpen: false,
  },
  reducers: {
    toggleAddModal: (state) => {
      state.isAddModalOpen = !state.isAddModalOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeviceList.fulfilled, (state, action) => {
      state.list = [...action.payload];
    });
  },
});

export const { toggleAddModal } = deviceListSlice.actions;
export default deviceListSlice.reducer;
