import React, { useState } from "react";
import { Button, Modal } from "antd";

const DeleteButton = (props) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "Вы действительно хотите удалить устройство:" + props.name
  );

  const showModal = () => {
    setOpen(true);
    console.log("opening");
  };

  const handleOk = () => {
    setModalText("Удаление устройства" + props.name + "...");
    setConfirmLoading(true);
    props.handleDelete(props.id).then(() => {
      setOpen(false);
      setConfirmLoading(false);
    });
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <>
      <Button danger onClick={showModal}>
        Удалить
      </Button>
      <Modal
        title="Удаление"
        open={open}
        onOk={handleOk}
        okButtonProps={{ danger: true }}
        cancelText={"Отменить"}
        okText={"Удалить"}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
};

export { DeleteButton };
