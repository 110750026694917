import React from "react";
import { Typography } from "antd";
import { Select, Button, Dropdown, Space } from "antd";

const { Title } = Typography;

const Action = () => {
  return (
    <div style={{ paddingTop: "10px", paddingLeft: "50px" }}>
      <Title level={3} style={{ marginBottom: "30px", fontWeight: 700 }}>
        Действия
      </Title>
      <div
        style={{
          width: "400px",
          height: "82px",
          backgroundColor: "white",
          borderRadius: "14px",
          paddingTop: "10px",
          paddingLeft: "20px",
        }}
      >
        <div style={{ display: "flex" }}>
          <Title
            level={5}
            style={{
              color: "#3333",
              margin: 0,
              paddingBottom: "5px",
              paddingLeft: "10px",
            }}
          >
            Устройство
          </Title>
        </div>
      </div>
    </div>
  );
};

export { Action };
