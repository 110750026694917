import React, { useEffect, useState } from "react";
import { Space, Table, Tag } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { fetchRequest } from "../../../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
} from "@nextui-org/react";
import {
  deletePetrolHistory,
  fetchPetrolHistrory,
} from "../../../../store/slices/deviceSettings/PetrolSettingsSlice";
import { render } from "@testing-library/react";

export const PetrolHistoryTable = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.petrolData.data);

  console.log("PetrolHistoryTable", props, data);
  useEffect(() => {
    dispatch(fetchPetrolHistrory(props.id));
    // const getTableData = async () => {
    //   const res = await fetchRequest(`/api/data-server/${props.id}`);
    //   console.log("getTableData res", res);
    //   setDataSource(
    //     res.map((elem) => ({ ...elem.doc, _id: elem._id, index: elem.index }))
    //   );
    // };
    // getTableData();
  }, []);

  const columns = [
    {
      title: "Заправка",
      dataIndex: "pertrolAmount",
      key: "pertrolAmount",
    },
    // {
    //   title: "АЗС",
    //   dataIndex: "petrolStation",
    //   key: "petrolStation",
    // },
    // {
    //   title: "Пробег",
    //   dataIndex: "mileage",
    //   key: "mileage",
    // },
    {
      title: "Дата заправки",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "",
      key: "action",
      width: "16px",
      render: (item) => {
        return (
          <Popover showArrow placement="right" backdrop={"blur"}>
            <PopoverTrigger>
              <Button size="sm" variant="light">
                <MoreOutlined />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <div className="px-1 py-2">
                <div className="text-small font-bold">Данные заправки</div>
                <div className="text-tiny">{item.pertrolAmount} л.</div>
                <div className="text-tiny">{item.petrolStation}</div>
                <div className="text-tiny">Пробег: {item.mileage}</div>
                <div className="text-tiny">{item.date}</div>
              </div>
              <Button
                color="danger"
                variant="bordered"
                size="sm"
                style={{ width: "100%", marginBottom: "6px" }}
                onClick={() => {
                  dispatch(
                    deletePetrolHistory({ id: props.id, docId: item._id })
                  );
                }}
              >
                Удалить
              </Button>
            </PopoverContent>
          </Popover>
        );
      },
    },
  ];

  //   В Таблице настроить сортировку по Дате
  return <Table columns={columns} dataSource={data} />;
};
