import "./App.css";
import { ScenarioList } from "./pages/scenarios/pages/ScenarioList/ScenarioList";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { SideNav } from "./components/SideNav";
import { ScenarioPage } from "./pages/scenarios/pages/ScenarioPage/ScenarioPage";
import { DeviceListWrapper } from "./pages/devices/pages/DeviceList/DeviceList";
import { DevicePageWrapper } from "./pages/devices/pages/DevicePage/DevicePage";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { store } from "./utils/store/store";
import { Login } from "./pages/login/Login";
import { SignIn } from "./pages/login/SignIn";
import { RequireAuth } from "./pages/login/RequireAuth";
import { useState } from "react";
import { HeaderBar } from "./components/HeaderBar";
import { NextUIProvider } from "@nextui-org/react";

function App() {
  const isLogged = localStorage.getItem("hutor-user");
  const [isNavOpen, setIsNavOpen] = useState(true);

  return (
    <NextUIProvider>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#5cba1e",
            },
          }}
        >
          {!isLogged && (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/sign-in" element={<SignIn />} />
            </Routes>
          )}
          <RequireAuth>
            <Provider store={store}>
              <div className="App">
                <HeaderBar setIsNavOpen={setIsNavOpen} isNavOpen={isNavOpen} />
                <div style={{ display: "flex" }}>
                  <SideNav isOpen={isNavOpen} />
                  <div
                    className="app-content-window"
                    style={{ width: "100%", paddingRight: "16px" }}
                  >
                    <Routes>
                      <Route path="/home" element={"home"} />
                      <Route path="/devices" element={<DeviceListWrapper />} />
                      <Route
                        path="/devices/:id"
                        element={<DevicePageWrapper />}
                      />
                      <Route path="/scenarios" element={<ScenarioList />} />
                      <Route path="/scenarios/:id" element={<ScenarioPage />} />
                      <Route path="/statistics" element={"statistics"} />
                      <Route path="/profile" element={"profile"} />
                    </Routes>
                  </div>
                </div>
              </div>
            </Provider>
          </RequireAuth>
          {/* {isLogged && (
          
        )} */}
        </ConfigProvider>
      </BrowserRouter>
    </NextUIProvider>
  );
}

export default App;
