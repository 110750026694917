import { ScenarioCard } from "./components/ScenarioCard";
import { useEffect, useState } from "react";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { HeaderPanel } from "./components/HeaderPanel";
import { Flex } from "antd";

const ScenarioList = (props) => {
  const [list, setlist] = useState([]);
  useEffect(() => {
    //запрос списка от сервера
    fetchRequest(
      `/api/scenarios?userId=${localStorage.getItem("hutor-user-id")}`
    )
      .then((res) => res)
      .then((data) => {
        setlist(data.doc);
      });
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <HeaderPanel />
      <Flex wrap="wrap" gap={16}>
        {list.map((listItem) => {
          return (
            <ScenarioCard title={listItem.scenario.name} item={listItem} />
          );
        })}
      </Flex>
    </div>
  );
};

export { ScenarioList };
