import { useRef, useState, useEffect, FC } from "react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

import React from "react";

export const Editor: FC = (props) => {
  const [editor, setEditor] =
    useState<monaco.editor.IStandaloneCodeEditor | null>(null);
  const monacoEl = useRef(null);

  useEffect(() => {
    if (monacoEl) {
      setEditor((editor) => {
        if (editor) return editor;

        return monaco.editor.create(monacoEl.current!, {
          value: props.value || "",
          language: "json",
          minimap: { enabled: false },
        });
      });
    }

    editor?.onDidChangeModelContent((event) => {
      props.onChange(editor.getValue(), event);
    });

    return () => editor?.dispose();
  }, [monacoEl.current]);

  return <div ref={monacoEl} style={props.style}></div>;
};
