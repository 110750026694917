import {
  BrowserRouter as Router,
  Navigate,
  useLocation,
} from "react-router-dom";

export const RequireAuth = (props) => {
  const { children } = props;
  let location = useLocation();
  //   Вообще надо сделать полную проверку
  if (!localStorage.getItem("hutor-user")) {
    // Redirect to the /login page
    console.log("children", children, location);

    if (location.pathname !== "/login" && location.pathname !== "/sign-in") {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return;
  }

  return children;
};
