import { configureStore } from "@reduxjs/toolkit";
import mqttReducer from "./mqttSlice";
import deviceListReducer from "../../pages/devices/store/slices/deviceListSlice";
import devicePageReducer from "../../pages/devices/store/slices/devicePageSlice";
import scenariosListReducer from "../../pages/scenarios/store/slices/scenariosListSlice";
import petrolDataReducer from "../../pages/devices/store/slices/deviceSettings/PetrolSettingsSlice";

export const store = configureStore({
  reducer: {
    mqttManager: mqttReducer,
    deviceList: deviceListReducer,
    devicePage: devicePageReducer,
    scenariosList: scenariosListReducer,
    petrolData: petrolDataReducer,
  },
});
