import { Card, Flex, Avatar, Typography, Divider, Switch } from "antd";
import { FireOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const { Title } = Typography;

export const ThermostatCard = (props) => {
  const { id } = props;
  const { mqttData, client } = useSelector((state) => state.mqttManager);
  const [thermostatData, setThermostatData] = useState();

  useEffect(() => {
    if (mqttData?.topic.indexOf(id) !== -1) {
      setThermostatData(mqttData?.message);
    }
  }, [mqttData]);

  return (
    <Card
      className="deviceCard"
      size="small"
      style={{ width: 300, height: 150 }}
    >
      <Flex align="baseline">
        <Avatar shape="square" icon={<FireOutlined />} />
        <Link to={`/devices/${id}`}>
          <Title level={5} style={{ margin: "0 0 8px 8px" }}>
            {props.data?.name || "Термостат"}
          </Title>
        </Link>
      </Flex>
      <Divider style={{ margin: "8px 0" }} />
      <Flex align="center">
        <Flex flex={1}>
          <p style={{ fontSize: "32px", margin: "8px 0 " }}>
            {JSON.stringify(thermostatData)} C
          </p>
        </Flex>
        <Flex align="flex-end">
          <Flex vertical>
            <table>
              <tr>
                <td>Контроль</td>
                <td></td>
              </tr>
              <tr>
                <td>Нагрев</td>
                <td>21</td>
              </tr>
            </table>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
