import { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { store } from "../../../../utils/store/store";
import { Button, Card, Flex, Input, Typography } from "antd";
import { LeftOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { fetchDevice } from "../../store/slices/devicePageSlice";
import { fetchRequest } from "../../../../utils/fetchRequest";
import { DeleteButton } from "./DeleteButton";
import { CustomSettings } from "./settings/CustomSettings.js";
import { PetrolSettings } from "./settings/PetrolSettings/PetrolSettings.js";

const { Title } = Typography;

export const DevicePageWrapper = (props) => {
  const { id } = useParams();
  return (
    <Provider store={store}>
      <DevicePage id={id} />
    </Provider>
  );
};

export const DevicePage = (props) => {
  const { id } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const devicePageState = useSelector((state) => state.devicePage);

  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState();

  useEffect(() => {
    dispatch(fetchDevice(id));
  }, []);

  const changeName = (e) => {
    setName(e.target.value);
  };

  const handleSaveEdit = async () => {
    const newData = { ...devicePageState.data };
    newData.name = name;
    await fetchRequest(
      `/api/devices/${id}`,
      { method: "PUT" },
      JSON.stringify(newData)
    );

    dispatch(fetchDevice(id));
  };

  const deleteDevice = async () => {
    const response = await fetchRequest(`/api/devices/${id}`, {
      method: "DELETE",
    });
    navigate("/devices");
    return response;
  };

  const renderEditButton = () => {
    const isDesktop = window.innerWidth > 640;

    const editBtn = isDesktop ? (
      <div>
        <EditOutlined /> Редактировать
      </div>
    ) : (
      <EditOutlined />
    );
    const saveBtn = isDesktop ? (
      <div>
        <SaveOutlined /> Сохранить
      </div>
    ) : (
      <SaveOutlined />
    );
    return isEdit ? saveBtn : editBtn;
  };

  const renderDeviceSettings = () => {
    switch (id.substring(0, 3)) {
      case "THS":
        return "Настройки термостата";
      case "SWH":
        return "Настройки выключателя";
      case "GTS":
        return "Настройки ворот";
      case "PET":
        return <PetrolSettings id={id} />;

      default:
        return <CustomSettings />;
        break;
    }
  };
  const renderDeviceInfo = () => {
    switch (id.substring(0, 3)) {
      case "THS":
        return "Инфо термостата";
      case "SWH":
        return "Инфо выключателя";
      case "GTS":
        return "Инфо ворот";
      case "PET":
        return <PetrolSettings id={id} />;

      default:
        return "Инфо";
        break;
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <Flex className="devicePageHeader" align="center" gap={16}>
        <Button
          type="text"
          icon={<LeftOutlined />}
          onClick={() => {
            navigate("/devices");
          }}
          style={{ color: "#5a9726" }}
        >
          Назад
        </Button>

        <Flex flex={1} align="center">
          <Title level={3} style={{ margin: 0 }}>
            {props.id}
          </Title>
        </Flex>

        <Flex justify="end">
          <Button
            type="text"
            onClick={() => {
              setIsEdit(!isEdit);
              handleSaveEdit();
            }}
            style={{ color: "#5a9726" }}
          >
            {renderEditButton()}
          </Button>
        </Flex>
      </Flex>

      <Card>
        {isEdit ? (
          <div>
            <Input
              value={name || devicePageState.data?.name || ""}
              onChange={changeName}
            />
            {renderDeviceSettings()}
          </div>
        ) : (
          <>
            {devicePageState.data?.name}
            {renderDeviceInfo()}
          </>
        )}
      </Card>

      <Flex justify="end" style={{ marginTop: "16px" }}>
        <DeleteButton
          id={id}
          name={name || devicePageState.data?.name || id}
          handleDelete={deleteDevice}
        />
      </Flex>
    </div>
  );
};
