import React, { useEffect, useState } from "react";
import { Button, Typography, Flex } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Condition } from "./components/Condition";
import { Action } from "./components/Action";
import { Editor } from "../../../../components/MonacoEditor.tsx";
import { fetchRequest } from "../../../../utils/fetchRequest";
import {
  createScenario,
  deleteScenario,
  updateScenario,
} from "../../store/slices/scenariosListSlice.js";
import { useDispatch } from "react-redux";

const { Title } = Typography;

const ScenarioPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [scenarioJson, setScenarioJson] = useState();

  const initialScenario = {
    id: "Identificator" + new Date().toISOString(),
    name: "Название сценария",
    isEnabled: true,
    automationConditions: [
      {
        firstOperandTopic: "",
        operator: "",
        comparisonValue: "",
      },
    ],
    operatorsBetweenConditions: [],
    actions: [
      {
        topic: "",
        payload: "",
      },
    ],
  };

  useEffect(() => {
    fetchRequest(`/api/scenarios/${id}`)
      .then((res) => res)
      .then((data) => {
        setScenarioJson(data?.doc?.scenario);
        if (id === "create") {
          setScenarioJson(initialScenario);
        }
      });
  }, []);

  const onSave = () => {
    if (id === "create") {
      dispatch(
        createScenario({
          userId: localStorage.getItem("hutor-user-id"),
          scenario: scenarioJson,
        })
      );
    } else {
      dispatch(
        updateScenario({
          id,
          userId: localStorage.getItem("hutor-user-id"),
          scenario: scenarioJson,
        })
      );
    }
  };

  const onDeleteScenario = () => {
    dispatch(deleteScenario(id));
    navigate("/scenarios");
  };

  const renderTitle = () => {
    const title = scenarioJson?.name || id;
    return id === "create" ? "Создание автоматизации" : title;
  };

  return (
    <div>
      <Flex className="devicePageHeader" align="center" gap={16}>
        <Button
          type="text"
          icon={<LeftOutlined />}
          onClick={() => {
            navigate("/scenarios");
          }}
          style={{ color: "#5a9726" }}
        >
          Назад
        </Button>

        <Flex flex={1} align="center">
          <Title level={3} style={{ marginBottom: "30px", fontWeight: 700 }}>
            {id === "create" ? "Создание автоматизации" : renderTitle()}
          </Title>
        </Flex>
      </Flex>
      {scenarioJson !== undefined && (
        <Editor
          style={{ height: "450px" }}
          value={JSON.stringify(scenarioJson, null, 2)}
          onChange={(e) => {
            try {
              const config = JSON.parse(e);
              setScenarioJson(config);
            } catch (e) {
              console.error(e);
            }
          }}
        />
      )}

      <Flex justify="end" gap={8} style={{ marginTop: "16px" }}>
        <Button danger onClick={onDeleteScenario}>
          Удалить
        </Button>
        <Button onClick={onSave}>Сохранить</Button>
      </Flex>
      {/* <div
        className="ScenarioPage"
        style={{
          width: "900px",
          height: "170px",
          backgroundColor: "white",
          borderRadius: "14px",
          paddingTop: "10px",
          paddingLeft: "20px",
        }}
      >
        <Title level={4} style={{ color: "", margin: 0, paddingBottom: "5px" }}>
          Название автоматизации
        </Title>
        <div style={{ width: "880px" }}>
          <Input placeholder="Введите название"></Input>
          <div style={{ display: "flex", marginTop: "50px", gap: "14px" }}>
            <div
              style={{
                width: "32px",
                height: "32px",
                backgroundColor: "#3333",
                borderRadius: "8px",
              }}
            ></div>
            <div
              style={{
                width: "32px",
                height: "32px",
                backgroundColor: "#3333",
                borderRadius: "8px",
              }}
            ></div>
            <ArrowRightOutlined />
            <div
              style={{
                width: "32px",
                height: "32px",
                backgroundColor: "#3333",
                borderRadius: "8px",
              }}
            ></div>
            <div
              style={{
                width: "32px",
                height: "32px",
                backgroundColor: "#3333",
                borderRadius: "8px",
              }}
            ></div>
          </div>
        </div>
      </div> */}
      {/* <div style={{ display: "flex" }}>
        <Condition />
        <Action />
      </div> */}
    </div>
  );
};

export { ScenarioPage };
