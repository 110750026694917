import { Card, Flex, Avatar, Typography, Divider, Switch, Button } from "antd";
import { HddOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const { Title } = Typography;

export const CustomCard = (props) => {
  const { id } = props;
  const { mqttData, client } = useSelector((state) => state.mqttManager);
  const device = props.data;

  // TODO: Надо добавить Memo чтобы он обновлял только тогда, когда данные пришли либо по id карточки, либо с топиков указанных в индикаторах
  // const cardData = mqttData?.topic.includes(id);

  const renderIndicators = () => {
    return device?.indicators?.map((indicator) => {
      const parser = new Function("msg", indicator.parser);

      const message =
        mqttData?.topic == indicator.topic ? mqttData?.message : "";

      const displayMessage = indicator.parser ? parser(message) : message;
      const styles = indicator?.styles ? indicator?.styles : {};
      return (
        <div style={styles}>
          {indicator.name !== "" ? indicator.name + ":" : ""} {displayMessage}
        </div>
      );
    });
  };

  const renderControls = () => {
    return device.customData?.controls?.map((controlElement) => {
      return (
        <div>
          <Button
            onClick={() => {
              if (client) {
                client.publish(
                  `${controlElement.actionHeader}`,
                  `${controlElement.actionBody}`,
                  { qos: 1 },
                  (error) => {
                    if (error) {
                      console.log("Publish error: ", error);
                    }
                  }
                );
              }
            }}
          >
            {controlElement.name}
          </Button>
        </div>
      );
    });
  };

  const Icon = ({ type, ...rest }) => {
    const icons = require(`@ant-design/icons`);
    const Component = icons[type];
    if (Component) {
      return <Component {...rest} />;
    } else {
      return [];
    }
  };

  return (
    <Card className="deviceCard" size="small">
      <Flex align="baseline">
        <Avatar
          shape="square"
          icon={<Icon type={device.icon ? device.icon.name : "HddOutlined"} />}
          style={{
            backgroundColor: device.icon
              ? device.icon.backgroundColor
              : "#f3f3f7",
            color: device.icon ? device.icon.color : "#a3a3a3",
          }}
        />
        <Link to={`/devices/${id}`}>
          <Title level={5} style={{ margin: "0 0 8px 8px" }}>
            {props.data?.name || "Пользовательское устройство"}
          </Title>
        </Link>
      </Flex>
      <Divider style={{ margin: "8px 0" }} />
      {renderIndicators()}
      <Flex gap={4} wrap="wrap">
        {renderControls()}
      </Flex>
    </Card>
  );
};
