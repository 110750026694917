import { Button, Card, Flex, Input, Typography, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
const { Title } = Typography;

export const SignIn = () => {
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [rePassword, setRePassword] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isSuccessSignIn, setIsSuccessSignIn] = useState(false);

  const onChangeLogin = (e) => {
    setLogin(e.target.value?.toLowerCase());
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onChangeRePassword = (e) => {
    setRePassword(e.target.value);
  };

  const handleSignIn = () => {
    const data = {
      login,
      password,
    };
    if (password === rePassword) {
      setLoading(true);
      fetch("/api/users", {
        method: "POST",
        headers: { "Content-Type": "application/json", Accept: "*/*" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          if (data.status === 200) {
            // redirect to /login
            // navigate("/login");
            setIsSuccessSignIn(true);
          } else {
            setError("Произошла ошибка:" + data?.error);
          }
        });
    } else {
      // Вывести ошибку, что пароли не совпадают
      setError("Пароли не совпадают");
    }
  };

  const renderBody = () => {
    return isSuccessSignIn ? (
      <Flex justify="center" vertical>
        <p style={{ textAlign: "center", fontSize: "16px" }}>
          Учетная запись успешно зарегистрированна. Теперь вы можете войти в
          учетную запись.
        </p>
        <Link
          to={"/login"}
          style={{
            fontSize: "16px",
            textAlign: "center",
            color: "#5cba1e",
          }}
        >
          Войти
        </Link>
      </Flex>
    ) : (
      <Flex gap={16} vertical>
        <Input placeholder="Логин" size="large" onChange={onChangeLogin} />
        <Input
          type="password"
          placeholder="Пароль"
          size="large"
          onChange={onChangePassword}
        />
        <Input
          type="password"
          placeholder="Подтвердите пароль"
          size="large"
          onChange={onChangeRePassword}
        />
        {error && (
          <p style={{ fontSize: "12px", color: "red", margin: 0 }}>{error}</p>
        )}
        <Flex justify="center">
          <Button type="primary" onClick={handleSignIn}>
            Зарегистрироваться
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <Card className="loginCard">
        <Flex gap={16} vertical>
          <Flex justify="center" vertical>
            <img
              style={{
                height: "60px",
                width: "60px",
                border: "1px solid #d4d4d4",
                borderRadius: "14px",
                margin: "0 auto",
              }}
              src="/hutor_icon.png"
            />
            <img
              style={{
                width: "100px",
                margin: "8px auto 0px",
              }}
              src="/text-logo.png"
            />
          </Flex>
          <Title level={2} style={{ marginTop: "0", textAlign: "center" }}>
            Регистрация в&nbsp;системе
          </Title>
          {loading ? (
            <Flex justify="center" vertical>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
              <p style={{ textAlign: "center" }}>Пожалуйста, подождите...</p>
            </Flex>
          ) : (
            renderBody()
          )}
        </Flex>
      </Card>
    </>
  );
};
