import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchRequest } from "../../../../utils/fetchRequest";

export const fetchDevice = createAsyncThunk(
  "devices/id",
  async (id, thunkApi) => {
    const data = await fetchRequest(`/api/devices/${id}`);
    return data;
  }
);

export const devicePageSlice = createSlice({
  name: "device_page",
  initialState: {
    name: "",
    data: {},
    loading: true,
    isAddModalOpen: false,
  },
  reducers: {
    changeName: (state, action) => {
      state.name = action.payload;
    },
    changeData: (state, action) => {
      state.data.name = action?.payload.name;
      state.data.customData = action?.payload.customData;
      state.data.indicators = action?.payload.indicators;
      state.data.icon = action?.payload.icon;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDevice.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const { changeName, changeData } = devicePageSlice.actions;
export default devicePageSlice.reducer;
