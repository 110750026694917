import { Card, Flex, Avatar, Typography, Divider, Button } from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const { Title } = Typography;

export const GatesCard = (props) => {
  // GTS10001 0 0 1 -- id закрыто открыто текущая позиция
  const { id } = props;
  const { mqttData, client } = useSelector((state) => state.mqttManager);
  const [gatesData, setGatesData] = useState("");
  const setnsorsCount = 3;

  useEffect(() => {
    if (mqttData?.topic.indexOf(id) !== -1) {
      setGatesData(mqttData?.message);
    }
  }, [mqttData]);

  const openGates = () => {
    if (client) {
      client.publish(`${id}/setState`, `O:1;`, { qos: 1 }, (error) => {
        if (error) {
          console.log("Publish error: ", error);
        }
      });
    }
  };

  const closeGates = () => {
    if (client) {
      client.publish(`${id}/setState`, `C:1;`, { qos: 1 }, (error) => {
        if (error) {
          console.log("Publish error: ", error);
        }
      });
    }
  };

  const getGatesImage = () => {
    // TODO: нужно будет переименновать картинки в обратную сторону. И отладить еще правильность ответов и картинок
    let data = gatesData;
    if (gatesData?.[gatesData?.length - 1] === ";") {
      data = gatesData.substring(0, gatesData?.length - 1);
    }
    const parts = (data || "")?.split(" ");

    if (parts[1] === "1") {
      return "/devices/gates/gates_100.png";
    } else if (parts[2] === "1") {
      return "/devices/gates/gates_10.png";
    } else {
      const persents = Math.floor(Number(parts[3] + "0") / setnsorsCount);
      return `/devices/gates/gates_${100 - persents * 10 || "100"}.png`;
    }
  };

  return (
    <Card className="deviceCard" size="small">
      <Flex align="baseline">
        <Avatar
          shape="square"
          icon={<PicCenterOutlined />}
          style={{ backgroundColor: "#f3ffeb", color: "#5cba1e" }}
        />
        <Link to={`/devices/${id}`}>
          <Title level={5} style={{ margin: "0 0 8px 8px" }}>
            {props.data?.name || "Ворота"}
          </Title>
        </Link>
      </Flex>
      <Divider style={{ margin: "8px 0" }} />
      <Flex align="center" vertical>
        <Flex style={{ margin: "16px" }}>
          <img
            style={{ width: "60%", height: "60%", margin: "auto" }}
            src={getGatesImage()}
          />
        </Flex>
        <Flex gap={8}>
          <Button onClick={openGates}>Открыть</Button>
          <Button onClick={closeGates}>Закрыть</Button>
        </Flex>
      </Flex>
    </Card>
  );
};
