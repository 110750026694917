import React from "react";
import { Typography } from "antd";
import { Select, Button, Dropdown, Space } from "antd";
import { DownCircleFilled } from "@ant-design/icons";
import { PlayCircleFilled } from "@ant-design/icons";
const { Title } = Typography;

const items: MenuProps["items"] = [
  {
    key: "1",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        1st menu item
      </a>
    ),
  },
];

const Condition = () => {
  return (
    <div style={{ paddingTop: "10px" }}>
      <Title level={3} style={{ marginBottom: "30px", fontWeight: 700 }}>
        Условия
      </Title>
      <div
        style={{
          width: "400px",
          height: "82px",
          backgroundColor: "white",
          borderRadius: "14px",
          paddingTop: "10px",
          paddingLeft: "20px",
        }}
      >
        <div style={{ display: "flex", gap: "100px" }}>
          <Title
            level={5}
            style={{
              color: "#3333",
              margin: 0,
              paddingBottom: "5px",
              paddingLeft: "10px",
            }}
          >
            Устройство
          </Title>
          <Title
            level={5}
            style={{
              color: "#3333",
              margin: 0,
              paddingBottom: "5px",
              paddingLeft: "18px",
            }}
          >
            Состояние
          </Title>
        </div>
        <div style={{ display: "flex", gap: "42px" }}>
          <Select
            defaultValue="SWH1001"
            style={{ width: 162, paddingLeft: "10px" }}
            disabled
          />
          <Select
            defaultValue="SWH1001"
            style={{ width: 162, paddingLeft: "10px" }}
            disabled
          />
        </div>
      </div>
      <DownCircleFilled style={{ paddingTop: "78px", paddingLeft: "200px" }} />
      <div
        style={{
          width: "452px",
          height: "82px",
          backgroundColor: "white",
          borderRadius: "14px",
          paddingTop: "10px",
          paddingLeft: "20px",
          marginTop: "50px",
        }}
      >
        <div style={{ display: "flex", gap: "100px" }}></div>
        <div style={{ display: "flex", gap: "42px" }}>
          <Select
            defaultValue="Устройство"
            style={{ width: 162, paddingLeft: "10px" }}
            disabled
          />
          <PlayCircleFilled />
          <Select
            defaultValue="Состояние"
            style={{ width: 162, paddingLeft: "10px" }}
            disabled
          />
        </div>
      </div>
      <div style={{ paddingTop: "12px", paddingLeft: "176px" }}>
        <Dropdown menu={{ items }} placement="bottom">
          <Button
            style={{
              borderColor: "#90ee90",
              borderRadius: "10px",
              borderWidth: "2px",
              color: "#90ee90",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            + Добавить
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export { Condition };
