import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Flex, Button, Dropdown, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { MqttManager } from "../utils/MqttManager/mqttManager";
import { useNavigate } from "react-router-dom";

export const HeaderBar = (props) => {
  const navigate = useNavigate();
  const toggleCollapsed = () => {
    props.setIsNavOpen(!props.isNavOpen);
  };

  const headerDropdownItems = [
    {
      label: (
        <Button
          style={{ color: "#333" }}
          type="link"
          icon={<LogoutOutlined />}
          onClick={() => {
            localStorage.removeItem("hutor-user");
            localStorage.removeItem("hutor-user-homeId");
            localStorage.removeItem("hutor-user-accessToken");
            localStorage.removeItem("hutor-user-id");
            localStorage.removeItem("hutor-user-refreshToken");
            navigate("/");
          }}
        >
          Выйти
        </Button>
      ),
      key: "1",
    },
  ];

  return (
    <header className="HUTOR" style={{ fontWeight: "500" }}>
      <Flex align="center" style={{ width: "100%", background: "#f3f3f7" }}>
        {window.innerWidth > 640 && (
          <Flex
            align="center"
            justify="center"
            style={{
              margin: "18px 0px 0px 16px",
              alignItems: "center",
            }}
          >
            <Button
              type="text"
              onClick={toggleCollapsed}
              style={{ marginBottom: 16 }}
            >
              {props.isNavOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
          </Flex>
        )}
        <Flex
          flex={1}
          className="navbar"
          style={{
            padding: "6px",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <img
            style={{
              height: "50px",
              width: "50px",
              border: "1px solid #d4d4d4",
              borderRadius: "12px",
            }}
            src="/hutor_icon.png"
          />
          <img
            style={{
              height: "14px",
            }}
            src="/text-logo-bw.png"
          />
        </Flex>

        <Flex justify="flex-end">
          <MqttManager />
        </Flex>

        <Flex justify="flex-end" style={{ marginRight: "32px" }}>
          <Dropdown menu={{ items: headerDropdownItems }} trigger={["click"]}>
            <a style={{ color: "#333" }} onClick={(e) => e.preventDefault()}>
              <Space>
                {/* Пользователь */}
                <UserOutlined />
              </Space>
            </a>
          </Dropdown>
        </Flex>
      </Flex>
      <hr
        style={{
          margin: "0px",
          border: "none",
          borderTop: "1px solid #d4d4d4",
        }}
      />
    </header>
  );
};
