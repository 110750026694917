import { Button } from "antd";
import { useState } from "react";
import { changeData } from "../../../store/slices/devicePageSlice";
import { Editor } from "../../../../../components/MonacoEditor.tsx";
import { useDispatch, useSelector } from "react-redux";

export const CustomSettings = () => {
  const dispatch = useDispatch();
  const [showEditorDescription, setShowEditorDescription] = useState(false);

  const devicePage = useSelector((state) => state.devicePage);

  const editorDescription = (
    <div style={{ color: "#555", fontSize: "12px" }}>
      <p>
        Расширенные настройки с помощью JSON редкатора позволяют создать
        пользовательскую карточку устройства.
      </p>
      <p>
        Можно настроить Кнопки на карточке в поле customData.controls. В этом
        массиве нужно указать пареметры кнопки. Например:
        <pre style={{ color: "#555", fontSize: "12px" }}>
          {`{
    "name": "Открыть",
    "type": "button",
    "actionType": "mqtt",
    "actionHeader": "ggg/setState",
    "actionBody": "O:0;"
}`}
        </pre>
      </p>
      <p>
        Можно настроить в карточку вывод инфортмации. Для этого используется
        поле indicators. Для создания индикатора необходимо указать Название,
        которое будет отображаться в карточке. Требуется также указать с какого
        топика mqtt нужно отображать информацию.
      </p>
      <p>
        Дополнительно можно настроить парсер для вывода сообщения в нужном
        формате. В поле parser нужно указать тело функции, которая будет
        возвращать строку. В функцию передается параметр msg, в котором
        находится сообщение с нужного mqtt топика. Еще можно указать стили для
        индикатора
        <pre style={{ color: "#555", fontSize: "12px" }}>
          {`{
    "name": "С парсером",
    "topic": "ggg",
    "parser": "return msg.split(' ')?.[1]",
    "styles": {
        "color": "red",
        "fontSize": "32px"
      }
}`}
        </pre>
      </p>
    </div>
  );

  return (
    <>
      <Editor
        style={{ height: "300px" }}
        value={JSON.stringify(devicePage?.data, null, 2) || ""}
        onChange={(e) => {
          try {
            const config = JSON.parse(e);
            dispatch(
              changeData({
                name: config.name,
                customData: config.customData,
                indicators: config.indicators,
                icon: config.icon,
              })
            );
          } catch (e) {
            console.error(e);
          }
        }}
      />
      <Button
        type="link"
        onClick={() => {
          setShowEditorDescription(!showEditorDescription);
        }}
      >
        {!showEditorDescription ? "Подробнее о редакторе" : "Скрыть"}
      </Button>
      {showEditorDescription && editorDescription}
    </>
  );
};
