import { Modal, Input } from "antd";
import { useState } from "react";
import { fetchRequest } from "../../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDeviceList,
  toggleAddModal,
} from "../../../store/slices/deviceListSlice";

export const AddDeviceModal = (props) => {
  const [id, setId] = useState();
  const isModalOpen = useSelector((state) => state.deviceList.isAddModalOpen);
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(toggleAddModal());
  };
  const handleOk = async () => {
    await fetchRequest(
      "/api/devices",
      { method: "POST" },
      JSON.stringify({ id })
    );
    dispatch(toggleAddModal());
    dispatch(fetchDeviceList());
    setId("");
  };

  const onChangeID = (e) => {
    setId(e.target.value);
  };

  return (
    <Modal
      title="Добавить устройство"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText={"Отмена"}
      okText={"Добавить"}
    >
      <Input
        placeholder="Введите идентификатор устройства"
        value={id}
        onChange={onChangeID}
      />
    </Modal>
  );
};
