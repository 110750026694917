import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Form, Switch } from "antd";
import { fetchRequest } from "../fetchRequest";

const MqttConnectionModal = (props) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [connectionInfo, setConnectionInfo] = useState();

  useEffect(() => {
    loadConnectionInfo();
  }, []);

  const loadConnectionInfo = async () => {
    const data = await fetchRequest("/api/mqttBrokerConnectionInfo");

    setConnectionInfo(data?.data?.data);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    fetchRequest(
      "/api/mqttBrokerConnectionInfo",
      { method: "POST" },
      JSON.stringify({ data: { ...connectionInfo } })
    ).then(() => {
      setOpen(false);
      setConfirmLoading(false);
      props.onChangeConnection();
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="text" onClick={showModal}>
        {props.buttonText}
      </Button>
      <Modal
        title="Подключение к брокеру"
        open={open}
        onOk={handleOk}
        cancelText={"Отменить"}
        okText={"Подключить"}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form>
          <Form.Item
            label="Протокол"
            name="protocol"
            initialValue={connectionInfo?.protocol}
            onChange={(e) => {
              const newConnectionInfo = { ...connectionInfo };
              newConnectionInfo.protocol = e.target.value;
              setConnectionInfo(newConnectionInfo);
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Host"
            name="host"
            initialValue={connectionInfo?.host}
            onChange={(e) => {
              const newConnectionInfo = { ...connectionInfo };
              newConnectionInfo.host = e.target.value;
              setConnectionInfo(newConnectionInfo);
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Port"
            name="port"
            initialValue={connectionInfo?.port}
            onChange={(e) => {
              const newConnectionInfo = { ...connectionInfo };
              newConnectionInfo.port = e.target.value;
              setConnectionInfo(newConnectionInfo);
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Пользователь"
            name="user"
            initialValue={connectionInfo?.user}
            onChange={(e) => {
              const newConnectionInfo = { ...connectionInfo };
              newConnectionInfo.user = e.target.value;
              setConnectionInfo(newConnectionInfo);
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Пароль"
            name="password"
            initialValue={connectionInfo?.password}
            onChange={(e) => {
              const newConnectionInfo = { ...connectionInfo };
              newConnectionInfo.password = e.target.value;
              setConnectionInfo(newConnectionInfo);
            }}
          >
            <Input type={"password"} />
          </Form.Item>
          <Form.Item
            label="Включить облако"
            initialValue={connectionInfo?.isBridgeEnabled}
            name="isBridgeEnabled"
          >
            <Switch
              value={connectionInfo?.isBridgeEnabled}
              onChange={(e) => {
                const newConnectionInfo = { ...connectionInfo };
                newConnectionInfo.isBridgeEnabled = e;
                setConnectionInfo(newConnectionInfo);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export { MqttConnectionModal };
