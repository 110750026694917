import React from "react";
import { Card, Flex, Avatar, Typography, Divider, Switch, Button } from "antd";
import { CarOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const { Title } = Typography;

export const PetrolCard = (props) => {
  const { id } = props;
  const { mqttData, client } = useSelector((state) => state.mqttManager);
  const [petrolData, setPetrolData] = useState();

  useEffect(() => {
    if (mqttData?.topic.indexOf(id) !== -1) {
      setPetrolData(mqttData?.message);
    }
  }, [mqttData]);

  return (
    <Card className="deviceCard" size="small">
      <Flex align="baseline">
        <Avatar shape="square" icon={<CarOutlined />} />
        <Link to={`/devices/${id}`}>
          <Title level={5} style={{ margin: "0 0 8px 8px" }}>
            {props.data?.name || "Бензобак"}
          </Title>
        </Link>
      </Flex>
      <Divider style={{ margin: "8px 0" }} />
      <Flex align="center">
        <Flex flex={1}>
          <p style={{ fontSize: "32px", margin: "8px 0 " }}>
            {petrolData || "--"} л.
          </p>
        </Flex>
        <Flex align="flex-end">
          <Button>Открыть</Button>
        </Flex>
      </Flex>
    </Card>
  );
};
