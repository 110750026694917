import { Modal, Input, Flex, Button } from "antd";
import { useState } from "react";
import { fetchRequest } from "../../../../../../utils/fetchRequest";
import { useDispatch, useSelector } from "react-redux";
import { fetchPetrolHistrory } from "../../../../store/slices/deviceSettings/PetrolSettingsSlice";

export const AddModal = (props) => {
  console.log("p", props);
  const [pertrolAmount, setPertrolAmount] = useState();
  const [petrolStation, setPetrolStation] = useState();
  const [mileage, setMileage] = useState();
  const defaultDate = new Date();
  const [date, setDate] = useState(
    `${defaultDate.getDate()}.${defaultDate.getMonth()}.${defaultDate.getFullYear()}`
  );
  const isModalOpen = useSelector((state) => state.deviceList.isAddModalOpen);
  const dispatch = useDispatch();

  const handleCancel = () => {
    props.setIsModalOpen(false);
  };
  const handleOk = async () => {
    await fetchRequest(
      `/api/data-server/${props.id}`,
      { method: "POST" },
      JSON.stringify({ pertrolAmount, petrolStation, mileage, date })
    );
    // dispatch(toggleAddModal());
    // dispatch(fetchDeviceList());
    // setId("");
    props.setIsModalOpen(false);
    dispatch(fetchPetrolHistrory(props.id));
  };

  return (
    <Modal
      title={"Добавить информацию о заправке" + props.id}
      open={props.isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText={"Отмена"}
      okText={"Добавить"}
    >
      <Flex vertical gap={8}>
        <Input
          placeholder="Введите количество литров"
          value={pertrolAmount}
          type="number"
          onChange={(e) => setPertrolAmount(e.target.value)}
        />
        <Input
          placeholder="Введите Название АЗС"
          value={petrolStation}
          onChange={(e) => setPetrolStation(e.target.value)}
        />
        <Input
          placeholder="Введите пробег"
          value={mileage}
          type="number"
          onChange={(e) => setMileage(e.target.value)}
        />
        <Input
          placeholder="Введите дату"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </Flex>
    </Modal>
  );
};
