export const fetchRequest = async (url, options, body) => {
  if (options) {
    const res = await fetch(url, {
      ...options,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "hutor-user-accessToken"
        )}`,
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body,
    });
    return await res.json();
  } else {
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "hutor-user-accessToken"
        )}`,
      },
    });
    return await res.json();
  }
};
