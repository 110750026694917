import { createSlice } from "@reduxjs/toolkit";

export const mqttSlice = createSlice({
  name: "mqtt",
  initialState: {
    mqttData: undefined,
    client: null,
  },
  reducers: {
    setMqttData: (state, action) => {
      state.mqttData = action.payload;
    },
    setMqttClient: (state, action) => {
      state.client = action.payload;
    },
  },
});

export const { setMqttData, setMqttClient } = mqttSlice.actions;
export default mqttSlice.reducer;
