import { Card, Flex, Avatar, Typography, Divider, Switch } from "antd";
import { BulbOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const { Title } = Typography;

export const SwitchCard = (props) => {
  const { id } = props;
  const numberFromId = Number(id[5]);
  const isMultiSwitch =
    isNaN(numberFromId) || numberFromId === 0 || numberFromId === 1
      ? false
      : true;
  const { mqttData, client } = useSelector((state) => state.mqttManager);

  const prepareSwitchStatus = () => {
    const states = [];
    const relayCount = isMultiSwitch ? numberFromId : 1;

    for (let i = 0; i < relayCount; i++) {
      states.push({ id, state: false, realayNumber: i + 1 });
    }
    return states;
  };

  const [switchStates, setSwitchStates] = useState(prepareSwitchStatus()); //prepareSwitchStatus()

  /*
  SWH10601 - 6 - Это количество реле
  SWH10601 1 21 0 - 21: два - это номер реле. 1/0 вкл или выкл 
  */
  useEffect(() => {
    setTimeout(() => {
      if (client) {
        console.log(`Request device status`, `${id}/setState`, `A:2;`);
        client.publish(`${id}/setState`, `A:2;`, { qos: 1 }, (error) => {
          if (error) {
            console.log("Publish error: ", error);
          }
        });
      }
    }, 1000);
  }, [client?.connected]);

  useEffect(() => {
    if (
      mqttData?.topic.indexOf(id) !== -1 &&
      mqttData?.topic.indexOf("set") === -1
    ) {
      const parts = mqttData?.message.split(" ");

      if (parts?.[2] !== undefined) {
        // Пакеты нового вида: ID switch_mode state errors;
        if (isMultiSwitch) {
          // Для многоканального
          const relayNumber = Number(parts[2][0]);
          const newState = [...switchStates];
          newState[relayNumber - 1].state = parts[2][1] === "1" ? true : false;
          setSwitchStates(newState);
        } else {
          // Для обычного выключателя
          const newState = [...switchStates];
          newState[0].state = parts[2] === "1" ? true : false;
          setSwitchStates(newState);
        }
      } else if (parts?.length === 1) {
        // Пакеты старого вида, где просто в сообщении 1 или 0
        const newState = [...switchStates];
        newState[0].state = parts?.[0] === "1" ? true : false;
        setSwitchStates(newState);
      }
    }
  }, [mqttData]);

  const sendCommand = (relayIndex) => (checked) => {
    const command = `${isMultiSwitch ? relayIndex : ""}${checked ? "1" : "0"}`;
    if (client) {
      console.log(`sendCommand L:${command};`);
      client.publish(`${id}/setState`, `L:${command};`, { qos: 1 }, (error) => {
        if (error) {
          console.log("Publish error: ", error);
        }
      });
    }
  };

  const renderSwitches = () => {
    const switchRows = [];
    const relayCount = isMultiSwitch ? numberFromId : 1;

    for (let i = 0; i < relayCount; i++) {
      switchRows.push(
        <Flex align="center">
          <Flex flex={1}>
            <p>Включить реле {isMultiSwitch ? i + 1 : "лампу"}</p>
          </Flex>
          <Flex align="flex-end">
            <Switch
              value={switchStates?.[i].state}
              onChange={sendCommand(i + 1)}
            />
          </Flex>
        </Flex>
      );
    }
    return switchRows;
  };

  return (
    <Card className="deviceCard" size="small">
      <Flex align="baseline">
        <Avatar
          shape="square"
          icon={<BulbOutlined />}
          style={{ backgroundColor: "#fff7ed", color: "#ff9500" }}
        />
        <Link to={`/devices/${id}`}>
          <Title level={5} style={{ margin: "0 0 8px 8px" }}>
            {props.data?.name || "Выключатель"}
          </Title>
        </Link>
      </Flex>
      <Divider style={{ margin: "8px 0" }} />
      {renderSwitches()}
    </Card>
  );
};
